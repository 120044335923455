var app = {

    init: function() {
        app.convas_bg();
        app.parallax();
        // app.menu();
        // app.modal();
        // app.sliders();
        // app.stages();
        // app.lazyload();
        // app.preloader();
        // app.formValidation();
        // app.phoneMask();
        // app.section_services();
    }, // init END

    convas_bg: function() {

        particlesJS.load('gel_description', 'js/particles-gel_description.json');
        particlesJS.load('gel_recipe', 'js/particles-gel_recipe.json');
        particlesJS.load('about_usage2', 'js/particles-gel_recipe.json');

    }, // convas_bg END

    parallax: function() {

        // var sceneFirst = document.getElementById('parallax_first_img');
        // var parallaxInstance = new Parallax(sceneFirst);

        // var sceneTabs = document.getElementById('parallax_tabs_img');
        // var parallaxInstance = new Parallax(sceneTabs);

    }, // parallax END
}

jQuery(document).ready(function($) {
    console.log( 'Документ и все ресурсы загружены' );
    app.init();

    $(function() {
        var sceneFirst = document.getElementById('parallax_first_img');
        var parallaxInstance = new Parallax(sceneFirst);

        var sceneTabs = document.getElementById('parallax_tabs_img');
        var parallaxInstance = new Parallax(sceneTabs);
    });

    var homeBlogSwiper = new Swiper('#home_blog-swiper', {
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            767: {
                slidesPerView: 2,
            },
            1023: {
                slidesPerView: 3,
            },
            1224: {
                slidesPerView: 4,
                // mySwiper.allowTouchMove = false,
            },
        },
    });

    $(function() {
        $("#nav_thumb_1st").on('click', function(){
            $(".nav_item, .images_item, .text_item").removeClass("active");
            $("#nav_thumb_1st, #images_item_1st, #text_item_1st").addClass("active");
        });
        $("#nav_thumb_2nd").on('click', function(){
            $(".nav_item, .images_item, .text_item").removeClass("active");
            $("#nav_thumb_2nd, #images_item_2nd, #text_item_2nd").addClass("active");
        });
        $("#nav_thumb_3rd").on('click', function(){
            $(".nav_item, .images_item, .text_item").removeClass("active");
            $("#nav_thumb_3rd, #images_item_3rd, #text_item_3rd").addClass("active");
        });
        $("#nav_thumb_4th").on('click', function(){
            $(".nav_item, .images_item, .text_item").removeClass("active");
            $("#nav_thumb_4th, #images_item_4th, #text_item_4th").addClass("active");
        });
    });

    $(function() {
        $("#disease_nav1").on('click', function(){ 
            $(".disease_nav_item, .disease_content_item").removeClass("active");
            $("#disease_nav1, #disease_content1").addClass("active");
        });
        $("#disease_nav2").on('click', function(){ 
            $(".disease_nav_item, .disease_content_item").removeClass("active");
            $("#disease_nav2, #disease_content2").addClass("active");
        });
        $("#disease_nav3").on('click', function(){ 
            $(".disease_nav_item, .disease_content_item").removeClass("active");
            $("#disease_nav3, #disease_content3").addClass("active");
        });
        $("#disease_nav4").on('click', function(){ 
            $(".disease_nav_item, .disease_content_item").removeClass("active");
            $("#disease_nav4, #disease_content4").addClass("active");
        });
        $("#disease_nav5").on('click', function(){ 
            $(".disease_nav_item, .disease_content_item").removeClass("active");
            $("#disease_nav5, #disease_content5").addClass("active");
        });
    });

    $(function(){
        $(".open_menu").on('click', function(){
            $(".menu_mob").addClass("active");
        });
        $(".close_menu").on('click', function(){
            $(".menu_mob").removeClass("active");
        });
    });

});
